<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-full">
        <feather-icon icon="UserIcon" class="mr-2" svgClasses="w-5 h-5" />
        <span class="leading-none font-medium">Role Details</span>
      </div>
      <div class="vx-col w-full md:w-2/3">
        <vs-input 
          class="w-full mt-4" 
          label="Role Name" 
          :danger="validation.name?true:false"
          :danger-text="validation.name?validation.name[0]:''"
          :value="form.name"
          @input="updateForm({key: 'name', value: $event})" />
      </div>
      <div class="vx-col w-full md:w-3/3">
         <!-- Permissions -->
        <vx-card class="mt-base" no-shadow card-border>

        <div class="vx-row">
            <div class="vx-col w-full">
            <div class="flex items-end px-3">
                <feather-icon svgClasses="w-6 h-6" icon="LockIcon" class="mr-2" />
                <span class="font-medium text-lg leading-none">Select permissions for this role.</span>
            </div>
            <vs-divider />
            </div>
        </div>

        <div class="block overflow-x-auto">
            <table class="w-full">
              <tr>
                  <th class="font-semibold text-base text-left px-3 py-2" v-for="heading in ['Module']" :key="heading">{{ heading }}</th>
              </tr>
              <tr v-for="(permission, index) in permissions" :key="index">
                  <td class="px-3 py-2">{{index}}</td>
                  <td class="px-3 py-2" v-for="(p, key) in permission" :key="key">
                      <vs-checkbox :value="form.permissions[p.id]" @input="updateForm({key: 'permissions', value: {event: $event, value: p.id}})">{{p.action}}</vs-checkbox>
                  </td>
              </tr>
            </table>
        </div>

        </vx-card>
      </div>
  </div>
  </div>
</template>

<script>

import vSelect,{vOption} from 'vue-select'
import { mapGetters,mapActions } from 'vuex'

export default {  
  components: {
    vSelect,
    vOption
  },

  computed: {
    ...mapGetters({
        form: 'role/getFormObj',
        permissions: 'role/getPermissions',
        validation: 'validation/getValidationErrors',
    })
  },
  methods: {
    ...mapActions({
      updateForm: 'role/updateForm',
      getAndSetPermissions: 'role/getAndSetPermissions',
    })
  },
  created() {
    this.getAndSetPermissions();
  }
}
</script>
