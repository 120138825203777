const loaderMixin = {
    methods: {
        openLoading(){
            this.$vs.loading({
                type:'default',
            })
        },
        closeLoading() {
            this.$vs.loading.close()
        },
    }
}

export default loaderMixin;